import { createTheme } from "@mui/material";

export interface PaletteBorderColor {
    hover: string;
    rest: string;
    main: string;
}

declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        graphite: true;
    }
}

declare module "@mui/material/styles" {
    interface Palette {
        graphite: Palette["primary"];
        surface: Palette["primary"];
        ashy: Palette["primary"];
        border: PaletteBorderColor;
    }

    interface PaletteOptions {
        graphite?: PaletteOptions["primary"];
        surface?: PaletteOptions["primary"];
        ashy?: PaletteOptions["primary"];
        border?: PaletteBorderColor;
    }
}

const theme = createTheme({
    palette: {
        primary: {
            main: "#0B84DA",
            dark: "#0A75C2",
            light: "#0D93F2",
            // green
            // main: "#0B8E83",
            // dark: "#09776D",
            // light: "#0CA597",
        },
        info: {
            main: "#0B84DA",
            dark: "#0A75C2",
            light: "#0D93F2",
        },
        graphite: {
            main: "#6C6F80",
            dark: "#474A59",
            light: "#ACAFBF",
        },
        success: {
            main: "#669900",
            dark: "#558000",
            light: "#77B200",
        },
        grey: {
            "50": "#FAFAFC",
            "100": "#ACAFBF",
            "500": "#6C6F80",
            "800": "#303240",
            "900": "#1D1E26",
        },
        error: {
            main: "#E52600",
            dark: "#CC2200",
            light: "#FF2B00",
        },
        warning: {
            main: "#E59900",
            dark: "#CC8800",
            light: "#FFAA00",
        },
        surface: {
            main: "#F2F2F3",
            dark: "#EAEBEC",
            light: "#FAFAFA",
        },
        ashy: {
            main: "#E3E6E8",
            dark: "#D5DADD",
            light: "#F1F3F3",
        },
        border: {
            main: "#e1e3eb",
            hover: "#282828",
            rest: "#CED0DB",
        },
        divider: "#EBEDF5",
        text: {
            primary: "#000000",
            secondary: "#5C5C70",
            disabled: "#ABABBA",
        },
        background: {
            default: "#F5F6FA",
        },
    },
    typography: {
        fontFamily: "Source Sans Pro, sans-serif",
        body1: {
            fontSize: "16px",
            lineHeight: "20px",
        },
        body2: {
            fontSize: "20px",
            lineHeight: "32px",
        },
        button: {
            textTransform: "capitalize",
            fontWeight: 600,
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 768,
            md: 1024,
            lg: 1200,
            xl: 1536,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState, theme }) => {
                    switch (ownerState.variant) {
                        case "contained":
                            return {
                                color: "#fff",
                                textTransform: "none",
                                "&.Mui-disabled": {
                                    opacity: 0.6,
                                },
                            };
                        case "outlined":
                            return {
                                paddingBlock: "6px",
                                paddingInline: "8px",
                                border: `1px solid currentColor`,
                                height: "35px",
                                textTransform: "none",
                                "&.Mui-disabled": {
                                    opacity: 0.6,
                                    background: "transparent",
                                },
                            };
                        case "text":
                            return {
                                paddingBlock: "6px",
                                paddingInline: "8px",
                                textTransform: "none",
                                "&.Mui-disabled": {
                                    opacity: 0.6,
                                },
                            };
                        default:
                            return;
                    }
                },
                sizeMedium: {
                    fontSize: "15px",
                    lineHeight: "23px",
                    height: "35px",
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    textTransform: "uppercase",
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    minHeight: "36px",
                    color: "#000",
                    textTransform: "none",
                    "& .Mui-selected": {
                        color: "#0D93F2",
                    },
                    ":hover": {
                        color: "#0D93F2",
                    },
                },
            },
            defaultProps: {
                TabIndicatorProps: {
                    sx: {
                        width: "3px",
                        left: 0,
                        right: "initial",
                    },
                },
            },
        },
    },
});

export default theme;
