import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TournamentFilter } from "../tournament/v1/models";
import { MemberFilter } from "@Admin/entities/member/model/memberFilter";
import { UserFilter } from "@Admin/pages/users/Users";
import { MotionFilter } from "@Admin/entities/motion/model/MotionFilter";
import { ClubFilter } from "@Admin/views/ClubContainer/ClubListContainer";
import { businessRoleIds } from "@Shared/helpers/businessRoles";
import { OrganizationFilter } from "@Admin/widgets/organization/lib/OrganizationListContext";

type FilterState = {
    tournamentsFilter: TournamentFilter;
    membersFilter: MemberFilter;
    usersFilter: UserFilter;
    motionsFilter: MotionFilter;
    clubsFilter: ClubFilter;
    organizationsFilter: OrganizationFilter;
    trainersFilter: MemberFilter;
};

const initialState: FilterState = {
    tournamentsFilter: {},
    membersFilter: {},
    usersFilter: {},
    motionsFilter: {},
    clubsFilter: {},
    organizationsFilter: {},
    trainersFilter: { businessRoleIds: [businessRoleIds.trainer] },
};

const filtersSlice = createSlice({
    name: "filter",
    initialState,
    reducers: {
        setTournamentFilter(state, action: PayloadAction<Partial<TournamentFilter>>) {
            state.tournamentsFilter = { ...state.tournamentsFilter, ...action.payload };
        },
        setMemberFilter(state, action: PayloadAction<Partial<MemberFilter>>) {
            state.membersFilter = { ...state.membersFilter, ...action.payload };
        },
        setUserFilter(state, action: PayloadAction<Partial<UserFilter>>) {
            state.usersFilter = { ...state.usersFilter, ...action.payload };
        },
        setMotionFilter(state, action: PayloadAction<Partial<MotionFilter>>) {
            state.motionsFilter = { ...state.motionsFilter, ...action.payload };
        },
        setClubFilter(state, action: PayloadAction<Partial<ClubFilter>>) {
            state.clubsFilter = { ...state.clubsFilter, ...action.payload };
        },
        setOrganizationFilter(state, action: PayloadAction<Partial<OrganizationFilter>>) {
            state.organizationsFilter = { ...state.organizationsFilter, ...action.payload };
        },
        setTrainersFilter(state, action: PayloadAction<Partial<MemberFilter>>) {
            state.trainersFilter = { ...state.trainersFilter, ...action.payload };
        },
        resetTournamentFilter(state) {
            state.tournamentsFilter = initialState.tournamentsFilter;
        },
        resetMemberFilter(state) {
            state.membersFilter = initialState.membersFilter;
        },
        resetUserFilter(state) {
            state.usersFilter = initialState.usersFilter;
        },
        resetMotionFilter(state) {
            state.motionsFilter = initialState.motionsFilter;
        },
        resetClubFilter(state) {
            state.clubsFilter = initialState.clubsFilter;
        },
        resetOrganizationFilter(state) {
            state.organizationsFilter = initialState.organizationsFilter;
        },
        resetTrainersFilter(state) {
            state.trainersFilter = initialState.trainersFilter;
        },
    },
});

export const {
    setTournamentFilter,
    setMemberFilter,
    setUserFilter,
    setMotionFilter,
    setClubFilter,
    setOrganizationFilter,
    setTrainersFilter,
    resetTournamentFilter,
    resetMemberFilter,
    resetUserFilter,
    resetMotionFilter,
    resetClubFilter,
    resetOrganizationFilter,
    resetTrainersFilter,
} = filtersSlice.actions;
const filterReducer = filtersSlice.reducer;

export default filterReducer;
